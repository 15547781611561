<template>
  <div class="draftboxIndexView content-index">
    <div style="padding-bottom:20px">
      <el-page-header
          style="width: 100%;padding:10px"
          @back="$router.go(-1)"
          content="草稿箱"
        ></el-page-header>
    </div>
    <div class="draftbox-list" v-if="draftboxList.length > 0">
      <el-card shadow="never" v-for="item in draftboxList" :key="item.id">
        <el-row>{{ item.realName }}</el-row>
        <el-row style="padding:5px 0">{{ item.phone }}</el-row>
        <el-row class="clothes">
          <div class="clothes-item" v-for="(item2,index) in item.orderDraDto.selectedClothes" :key="index">
            <span class="name">{{ item2.clothesName }}</span>
            <span class="price">￥{{ item2.realPrice ? item2.realPrice : 0 }}</span>
          </div>
        </el-row>
        <div class="bottom-item">
          <span class="name">已选衣物</span>
          <span class="text">{{ item.clothesNum }}件</span>
        </div>
        <div class="bottom-item">
          <span class="name">保存时间</span>
          <span class="text">{{ item.createTime }}</span>
        </div>
        <div class="bottom-item">
          <span class="name">更新时间</span>
          <span class="text">{{ item.updateTime }}</span>
        </div>
        <div class="bottom-item" style="margin-top:20px">
          <span class="continue-button">
            <el-button type="primary" size="medium" @click="queryClothesDraftbox(item.id)"
            >继续收衣</el-button
            >
          </span>
          <span class="close-button">
            <el-button size="medium" @click="deleteDraftboxClothes(item.id)"
            >取消保存</el-button
            >
          </span>
        </div>
      </el-card>
    </div>
    <div
        v-else
        style="display: flex;color: #666666;align-items: center;justify-content:center;height:100%"
    >
      <div> 暂无数据</div>
    </div>
  </div>
</template>

<script>
import {
  listDraftbox,
  deleteClothesDraftboxs,
  queryClothesDraftboxById,
} from "@/api/clothes/clothes";

export default {
  name: "index",
  data() {
    return {
      draftboxList: [],
      orderDetailOption: {},
    };
  },
  created() {
    this.listDraftbox();
  },
  mounted() {
  },
  methods: {
    // 草稿箱列表
    listDraftbox() {
      listDraftbox().then((response) => {
        console.log(response);
        this.draftboxList = response.data.data;
      });
    },
    // 删除衣物
    deleteDraftboxClothes(id) {
      this.$confirm("确定删除草稿箱衣物吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            deleteClothesDraftboxs(id).then((response) => {
              if (response.data.code === 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.listDraftbox();
              }
            });
          })
          .catch(() => {
          });
    },
    queryClothesDraftbox(id) {
      this.$confirm("确定继续收衣吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            queryClothesDraftboxById(id).then((response) => {
              console.log(response);
              if (response.data.code === 0) {
                let orderDetailOption = response.data.data;
                console.log(orderDetailOption);
                orderDetailOption.draId = id;
                this.$router.push({
                  name: "types",
                  params: {data: JSON.stringify(orderDetailOption)},
                });
              }
            });
          })
          .catch(() => {
          });
    },
  },
};
</script>

<style scoped lang="scss">
.draftboxIndexView {
  position: relative;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  overflow: auto;
  padding: 20px;
  /* transform: translateX(1024px); */
  /* transform: translateX(1024px); */
  .draftbox-list {
    display: flex;
    flex-flow: wrap;

    .el-card {
      width: 320px;
      height: 480px;
      margin: 0 20px 20px 0;
      color: rgba(137, 137, 137, 100);
      font-size: 16px;

      .clothes {
        padding: 20px;
        border-radius: 5px;
        background-color: rgba(243, 243, 245, 100);
        margin: 10px 0;
        height: 230px;
        overflow: auto;

        .clothes-item {
          display: flex;
          justify-content: space-between;
          padding-bottom: 5px;

          .name {
            color: #101010;
          }
        }
      }

      .bottom-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .text {
          color: #101010;
        }
      }
    }
  }
}
</style>
